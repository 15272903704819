.flag-dropdown {

background: white !important;
padding: 7px 17px 0px 12px !important;
}

.react-tel-input .selected-flag .flag {
    position: absolute;
    top: 50%;
    margin-top: -7px;
    zoom: 1.5;
    margin-right: 3px;
}

.react-tel-input .country-list {

    top: 44px;
    left: 0;
}

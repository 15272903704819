.main_container {
//   background-image: url('./assets/img/draw.svg');
//   // background-image: url('./assets/img/main_bg_2.png');
//   width: 100%;
//   height: 800px;
// //   height: 90vh;
//   background-size: cover;
//   background-position: bottom;
//   background-repeat: no-repeat;



}
.abs {
  position: absolute;
  top: 0;
}

// GLOBAL STYLESHEET
@import './variables.scss';
@import './fonts.scss';


body {
    margin: 0;
    // background-color: $primary-color;
    font-family: -apple-system, 'NunitoSans', 'Inter-Variable',sans-serif;
      /* font-family: "NunitoSans"; */
      /* font-family: "Inter-Variable"; */
      font-weight: bolder;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  
.j {
    border: solid 1px black;
}


.main_txt {
  font-family: 'NunitoSans';
font-size: 40px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 1.07;
letter-spacing: normal;
text-align: center;
color: #f14d03;
margin-bottom: 15px;
}


a {
  color: inherit;
}

a:hover {
  text-decoration: none;
  color: #f14d03;
  
}
.form_wrapper {
  margin: 50px auto 0 auto;
  max-width: 470px;
  .boxer {
    display: block;
    width: 100%;
    // height: 461px;
    height: auto;
    // margin: 86px 0px 317px 23px;
    // margin: 86px 40px 317px 23px;
    padding: 38px 55px;
    border-radius: 8px;
    box-shadow: 0 8px 40px 0 rgba(9, 44, 76, 0.16);
    background-color: #ffffff;
  }

  .input {
    padding: 25px 0px 25px 10px;
    border-radius: 4px;
    border: solid 1px #bdbdbd;

    font-family: 'Inter-Variable';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4f4f4f;
    // width: auto;
  }
  .input_2 {
    padding: 27px 0px 27px 79px !important;
    // padding: 27px 0px 27px 90px;
    border-radius: 4px;
    border: solid 1px #bdbdbd;

    font-family: 'Inter-Variable';
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4f4f4f;
    width: 100% !important;
  }
  .checkbox {
    border-radius: 4px;
    border: solid 1px #bdbdbd;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4f4f4f;
    width: 20px;
    height: 19px;
    margin-right: 14px;
    position: relative;
    border: none;
    box-shadow: none;
    border: solid 0.5px #cacaca !important;
    -webkit-appearance: none;
    &:checked:focus {
      border: none;
      box-shadow: none;
    }
    &:checked:after {
      background-color: #f2581e !important;
      -webkit-appearance: none;
      appearance: none;

      top: 0;
      border-radius: 4px;
      left: -1px;
      width: 20px;
      height: 19px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      content: '\2713';
      font-size: 17px;
      color: white;
    }
  }

  .checkbox_w {
    display: flex;
    align-items: center;

    font-family: 'Inter-Variable';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4f4f4f;
  }
  .text_danger {
    color: #f2581e !important;
    margin: 0;
    font-size: 11px;
    font-family: 'Inter-Variable';
    font-weight: 300;
  }

  .buttonenv {
    border-radius: 8px;
    background-color: #f14d03;
    padding: 12px 0;

    border: none;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  .first_1 {
    padding-right: 7px ;
  }
  .first_2 {
    padding-left: 6px ;
  }

  @media (max-width: 992px) { 
    max-width: 100%;
  }

}


@media (max-width: 768px) { 
  .form_wrapper {
    .boxer {
      width: auto;
     margin: auto;
     padding: 38px 17px;
    }
  }

  .first_1 {
    padding-right: 15px !important ;
  }
  .first_2 {
    padding-left: 15px !important ;
  }

}
@media (max-width: 992px) { 
  .MainSection{
    .formfix{
      display: block;
    }
  }
  
}


.main_wrapper_p {
  display: block;
  // margin-bottom: 100vh;
  height: 100vh;
  min-height: 700px;
  .res_fix {
    display: flex;
    flex-direction: row-reverse;
    background-image: url('./../../assets/img/draw.svg');
    // background-image: url('./assets/img/main_bg_2.png');
    // width: 100%;
    height: auto;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    padding-bottom: 60px;
    min-height: 900px;
    
  }
  .formfix {
    display: flex;
    justify-content: flex-end;
  }
  .text_content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    height: 60%;
    margin-left: 20px;

    h1 {
      font-family: 'NunitoSans';
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: normal;
      color: #f14d03;
      margin-bottom: 25px;
    }
    p {
      font-family: 'Inter-Variable';
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2f4858;
    }
  }

  @media (max-width: 992px) { 

    min-height: 1600px;
    .res_fix {
      display: flex;
      flex-direction: column-reverse !important;
      background: none;
    }
  
  }
}
@media only screen and (min-width: 1112px)  and (max-width: 1115px){ 
  .res_fix {
    background-size: 640px !important;
  }
}
@media only screen and (min-width: 1024px)  and (max-width: 1025px){ 
  .res_fix {
    background-size: 740px !important;
  }
}


@media (max-width: 768px) { 
  .main_wrapper_p {
    margin-bottom: 0px;
    padding-top: 122px;
  }
  .res_fix {
    display: flex;
    flex-direction: column-reverse !important;
  }

}



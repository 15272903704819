@font-face {
    font-family: 'Inter-Variable';
    src: local('Inter-VariableFont_slnt'), url(./../assets/fonts/Inter/Inter-VariableFont_slntwght.ttf) format('truetype');
    font-weight: 100 900;
  } 
  @font-face {
    font-family: 'NunitoSans';
    src: url(./../assets/fonts/Nunito_Sans/NunitoSans-Regular.ttf) format('truetype') ;
    font-weight: 500;
    font-style: normal;
  } 
  @font-face {
    font-family: 'NunitoSans-Light';
    src: url(./../assets/fonts/Nunito_Sans/NunitoSans-Light.ttf) format('truetype') ;
    font-weight: lighter;
    font-style: normal;
  } 
  
  @font-face {
    font-family: 'NunitoSans';
    src: url(./../assets/fonts/Nunito_Sans/NunitoSans-SemiBold.ttf) format('truetype') ;
    font-weight: normal;
    font-style: normal;
  } 
  @font-face {
    font-family: 'NunitoSans';
    src: url(./../assets/fonts/Nunito_Sans/NunitoSans-Bold.ttf) format('truetype') ;
    font-weight: bold;
    font-style: normal;
  } 
  @font-face {
    font-family: 'NunitoSans';
    src: url(./../assets/fonts/Nunito_Sans/NunitoSans-ExtraBold.ttf) format('truetype') ;
    font-weight: bolder;
    font-style: normal;
  } 
  
  
  